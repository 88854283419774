import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.css";
import cheersAILogo from "../images/CheersAI.png"; // Adjust the path as per your project structure

const nav__links = [
  {
    path: "/", // Updated path for Home
    display: "Home",
  },
  {
    path: "/about", // Updated path for About
    display: "About",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Header = ({ theme, toggleTheme }) => {
  const headerRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(true);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const headerFunc = () => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      headerRef.current.classList.add("header__shrink");
    } else {
      headerRef.current.classList.remove("header__shrink");
    }
  };

  // Scroll to the top of the page when route changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", headerFunc);
    return () => window.removeEventListener("scroll", headerFunc);
  }, []);

  const navigateToLogin = () => {
    window.location.href = "https://cheersai.co/login";
  };

  return (
    <header className={`header ${menuOpen ? 'header__open' : ''} ${theme}`} ref={headerRef}>
      <div className="nav__wrapper">
        <div className="logo">
          <Link to="/" className="logo">
            <img src={cheersAILogo} alt="CheersAI Logo" className="logo__image" />
            <h2>CheersAI</h2>
          </Link>
        </div>
        {/* Navigation */}
        <nav className={` ${menuOpen ? 'menu__active' : ''}`}  >
          <ul className={`menu`}>
            {nav__links.map((item, index) => (
              <li className={`menu__item`} key={index}>
                <Link 
                  to={item.path} 
                  className={`${!menuOpen ? 'menu__link' :'menu__item__hide'} ${theme === "light-theme" ? "light-menu__link" : ""}`}
                  onClick={() =>{
                     window.scrollTo({ top: 0, behavior: 'smooth' })
                     setMenuOpen(true)}}
                >
                  {item.display}
                </Link>
              </li>
            ))}
            <p className="get__started__button" >
              <a 
               href="#!" 
               className={`${!menuOpen ? 'menu__link' :'menu__item__hide'} ${theme === "light-theme" ? "light-menu__link" : ""}`}
               onClick={navigateToLogin}
              >
                Get Started
              </a>
            </p>
          </ul>
        </nav>

        {/* Light Mode */}
        <div className="light__mode">
          <span onClick={toggleTheme}>
            {theme === "light-theme" ? (
              <span>
                <i className="ri-moon-line"></i>Dark
              </span>
            ) : (
              <span>
                <i className="ri-sun-line"></i>Light
              </span>
            )}
          </span>
        </div>

        <span className="mobile__menu" onClick={toggleMenu}>
          <i className="ri-menu-line"></i>
        </span>
      </div>
    </header>
  );
};

export default Header;
