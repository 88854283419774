import React from "react";
import "../styles/counter.css";

const Counter = () => {
  return (
    <section className="counter" id="projects">
      <div className="container">
        <div className="counter__quote">
          <p className="quote__text">"Rates of depression have been increasing globally. We're here for those who struggle."</p>
        </div>
      </div>
    </section>
  );
};

export default Counter;
