import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import NewAbout from "./components/UI/newAbout";
import Counter from "./components/UI/Counter";
import Hero from "./components/UI/Hero";
import Newsletter from "./components/UI/Newsletter";
import Services from "./components/UI/Services";
import Testimonials from "./components/UI/Testimonials";

function App() {
    const [theme, setTheme] = useState("dark-theme"); 

    const toggleTheme = () => {
        theme === "light-theme" ? setTheme("dark-theme") : setTheme("light-theme");
    };

    useEffect(() => {
        document.body.className = theme; // Set the class of <body> based on the theme state
    }, [theme]);

    return (
        <Router>
            <>
                <Header theme={theme} toggleTheme={toggleTheme} />
                <Routes>
                    <Route path="/" element={<Home theme={theme} />} />
                    <Route path="/home" element={<Navigate to="/" />} /> {/* Redirect /home to / */}
                    <Route path="/about" element={<NewAbout theme={theme} />} /> {/* Pass theme prop to NewAbout */}
                    <Route path="/contact" element={<Newsletter theme={theme} />} /> {/* Pass theme prop to NewAbout */}
                </Routes>
                <Footer />
            </>
        </Router>
    );
}
const Home = ({ theme }) => (
    <>
      <Hero theme={theme} />
      <Counter />
      <section id="services">
        <Services />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>
    </>
  );
  
export default App;
