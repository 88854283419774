import React from "react";
import "../styles/services.css";

const serviceData = [
  {
    icon: "ri-chat-3-line",
    title: "Chat",
    desc: "Chat with an AI Therapist that remembers, personalizes, and adapts to your unique interactions.",
  },
  {
    icon: "ri-phone-line",
    title: "Call",
    desc: "Call our AI Therapist for a uniquely humanistic conversation tailored to your needs and preferences.",
  },
  {
    icon: "ri-user-settings-line",
    title: "Personalization",
    desc: "CheersAI's Therapist remembers your conversations in order to deliver personalized care.",
  },
  {
    icon: "ri-shield-check-line",
    title: "Analytics",
    desc: "Coming Soon!",
  },
];

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="service__top-content">
          <h6 className="subtitle">Our Features</h6>
          <h2>Move One Step Forward</h2>
          <h2 className="highlight"> </h2>
        </div>

        <div className="services__item-wrapper">
          {serviceData.map((item, index) => (
            <div
              className={`services__item ${item.title === "Analytics" ? "services__item--large" : ""}`}
              key={index}
            >
              <span className={`service__icon ${item.title === "Analytics" ? "service__icon--analytics" : ""}`}>
                <i className={item.icon}></i>
              </span>
              <h3 className={`service__title ${item.title === "Analytics" ? "service__title--analytics" : ""}`}>{item.title}</h3>
              <p className={`description ${item.title === "Analytics" ? "description--analytics" : ""}`}>{item.desc}</p>


            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
